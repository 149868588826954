body {
  background-color: #1f1b12;
  background-image: url("img/background.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.footer {
  margin-top: 3em;
}

.footer hr {
  margin-bottom: 2em;
}

/* Social */
.social {
  font-size: 1.5em;
  /* margin-top: 2em; */
}

blockquote {
  font-style: italic;
}

.header {
  background: #121617;
  text-align: center;
  padding: 2em 0;
}

.section-wrapper {
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid #bda179;
  padding: 1em;
  margin-bottom: 2em;
}

.section {
  background: #fff;
  /* background:rgba(255, 255, 255, 0.25); */
  border: 2px solid #bda179;
  padding: 2em;
}

.card {
  border: 0;
}

.card,
.card-header {
  border: 0 !important;
  border-radius: 0 !important;
}

label {
  display: initial;
}

.card-header {
  background-color: transparent;
  padding: .75rem 0;
}

.heading,
h1,
h4,
h5,
h6 {
  font-family: aw-conqueror-didot, serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  color: #fff;
}

h2 {
  font-weight: 700;
}

h2 > button {
  padding: 0 !important;
  text-transform: uppercase;
  text-decoration: underline !important;
  text-decoration-color: #bda179 !important;
}

.btn-link {
  font-size: 0.7em;
  font-weight: bold;
  color: #bda179;
  text-decoration-color: #6f6f6f !important;
}

.btn-link span {
  color: #bda179;
}

.btn-link span:hover {
  color: #ac8957 !important;
}

.btn-link:hover {
  color: #ac8957 !important;
}

h2, h3, p {
  font-family: 'Muli', sans-serif !important;
}

* {
  color: #6f6f6f;
}

p {
  font-size: 1.1em;
}

a {
  color: #bda179 !important;
}

a:hover {
  color: #ac8957 !important;
}

.btn {
  border: none;
  padding: .75rem;
}

.btn:hover {
  border: none;
}

.btn-primary {
  background: #bda179 !important;
  border-color: #bda179 !important;
}

.btn-primary:hover {
  background: #ac8957 !important;
  border-color: #ac8957 !important;
}

.btn-facebook {
  background: #1877f2 !important;
}

.btn-facebook:hover {
  background: #4893f5 !important;
}

.btn-google {
  background: #4285f4 !important;
  color: #fff;
}

.btn-google:hover {
  color: #fff;
  background: #1266f1 !important;
}

.btn-link .collapsed ::before {
  font-family: FontAwesome;
  color: #fff;
  background: #bda179;
  padding: 1em 0.55em;
  border-radius: 50px;
  line-height: 0;
  margin-right: 1em;
  display: block;
  width: 50px;
  margin: 1em auto;
}

#facebook h2 > button::before {
  font-family: FontAwesome;
  content: "\f09a";
  color: #fff;
  background: #bda179;
  padding: 1em 0.75em;
  border-radius: 50px;
  line-height: 0;
  margin-right: 1em;
  display: block;
  width: 50px;
  margin: 1em auto;
}

#email h2 > button::before {
  font-family: FontAwesome;
  content: "\f0e0";
  color: #fff;
  background: #bda179;
  padding: 1em 0.55em;
  border-radius: 50px;
  line-height: 0;
  margin-right: 1em;
  display: block;
  width: 50px;
  margin: 1em auto;
}

#google h2 > button::before {
  font-family: FontAwesome;
  content: "\f1a0";
  color: #fff;
  background: #bda179;
  padding: 1em 0.55em;
  border-radius: 50px;
  line-height: 0;
  margin-right: 1em;
  display: block;
  width: 50px;
  margin: 1em auto;
}

#facebook h2 > button::before,
#email h2 > button::before,
#google h2 > button::before {
  text-decoration: none !important;
}

.btn-link {
  text-decoration: none !important;
}

.btn-link span {
  text-decoration: underline !important;
}

.footer {
  margin-top: 3em;
  padding: 1em;
}

.footer .fa {
  background: #6f6f6f;
  color: #fff;
  border-radius: 50%;
  height: 40px;
  line-height: 40px;
  width: 40px;
  margin: 8px 4px;
}

.footer .fa:hover {
  background: #898989;
}

.form-group label {
  display: block !important;
  margin-bottom: 0.5rem;
}

.form-group .form-check-label {
  display: initial !important;
}

.form-control {
  border: 1px solid #bda179;
  border-radius: 0;
}

iframe {
  width: 100%;
}

.alert-danger a {
  color: #721c24 !important;
  text-decoration: underline;
}

.alert-danger a:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.0rem;
  }
  img {
    width: 150px;
  }
  .section-wrapper {
    padding: 0.3em;
  }
  .card-body {
    padding: 0;
  }
}

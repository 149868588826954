// Variables
$color__oldwalls_primary: #bda179;
$color__oldwalls_secondary: #6f6f6f;

$color__oldwalls-link: $color__oldwalls_primary;
$color__oldwalls-link-hover: darken($color__oldwalls-link, 10%);

$color__facebook: #1877f2;
$color__google: #4285f4;

// Imports
@import "custom";

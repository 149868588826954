body {
    background-color: #1f1b12;
    background-image: url("img/background.jpg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.footer {
    margin-top: 3em;
}

.footer hr {
    margin-bottom: 2em;
}

/* Social */
.social {
    font-size: 1.5em;
    /* margin-top: 2em; */
}

blockquote {
    font-style: italic;
}

.header {
    background: #121617;
    text-align: center;
    padding: 2em 0;
}

// .content {
//     background-image: url('img/background.jpg');
//     background-repeat: no-repeat;
//     background-position: top center;
//     background-size: cover;
// }

.section-wrapper {
    background: rgba(255, 255, 255, 0.25);
    border: 1px solid $color__oldwalls_primary;
    padding: 1em;
    margin-bottom: 2em;
}

.section {
    background: #fff;
    /* background:rgba(255, 255, 255, 0.25); */
    border: 2px solid $color__oldwalls_primary;
    padding: 2em;

}

.card {
    border: 0;
}

.card,
.card-header {
    border: 0 !important;
    border-radius: 0 !important;
}

label {
    display: initial;
}

.card-header {
    background-color: transparent;
    padding: .75rem 0;
}

.heading,
h1,
h4,
h5,
h6 {
    font-family: aw-conqueror-didot, serif;
    font-weight: 400;
    font-style: normal;
}

h1 {
    color: #fff;
}

h2 {
    font-weight: 700;
}

h2 > button {
    padding: 0 !important;
    text-transform: uppercase;
    // color: $color__oldwalls_secondary !important;
    text-decoration: underline !important;
    text-decoration-color: $color__oldwalls-link !important;
    // border-bottom: 1px solid $color__oldwalls-link !important;
}

.btn-link {
    font-size: 0.7em;
    font-weight: bold;
    color: $color__oldwalls_primary;
    text-decoration-color: $color__oldwalls_secondary !important;
    span {
        color: $color__oldwalls_primary;
        &:hover {
            color: $color__oldwalls-link-hover !important;
        }
    }
    &:hover {
        color: $color__oldwalls-link-hover !important;
    }
}

h2, h3, p {
    font-family: 'Muli', sans-serif !important;
}

* {
    color: $color__oldwalls_secondary;
    // text-align: center;
}

p {
    font-size: 1.1em;
}

a {
    color: $color__oldwalls-link !important;

    &:hover {
        color: $color__oldwalls-link-hover !important;
    }
}

.btn {
    border: none;
    padding: .75rem;
    &:hover {
        border: none;
    }
}

.btn-primary {
    background: $color__oldwalls-link !important;
    border-color: $color__oldwalls-link !important;

    &:hover {
        background: $color__oldwalls-link-hover !important;
        border-color: $color__oldwalls-link-hover !important;
    }
}

// Facebook button
.btn-facebook {
    background: $color__facebook !important;
    &:hover {
        background: lighten($color__facebook, 10%) !important;
    }
}

// Google button
.btn-google {
    background: $color__google !important;
    color: #fff;
    &:hover {
        color: #fff;
        background: darken($color__google, 10%) !important;
    }
}

.btn-link .collapsed {
    ::before {
        font-family: FontAwesome;
        color: #fff;
        background: $color__oldwalls_primary;
        padding: 1em 0.55em;
        border-radius: 50px;
        line-height: 0;
        margin-right: 1em;
        display: block;
        width: 50px;
        margin: 1em auto;
    }
}

// Facebook icon
#facebook h2 > button::before {
    font-family: FontAwesome;
    content: "\f09a";
    color: #fff;
    background: $color__oldwalls_primary;
    padding: 1em 0.75em;
    border-radius: 50px;
    line-height: 0;
    margin-right: 1em;
    display: block;
    width: 50px;
    margin: 1em auto;
}

// Email icon
#email h2 > button::before {
    font-family: FontAwesome;
    content: "\f0e0";
    color: #fff;
    background: $color__oldwalls_primary;
    padding: 1em 0.55em;
    border-radius: 50px;
    line-height: 0;
    margin-right: 1em;
    display: block;
    width: 50px;
    margin: 1em auto;
}

// Google icon
#google h2 > button::before {
    font-family: FontAwesome;
    content:"\f1a0";
    color: #fff;
    background: $color__oldwalls_primary;
    padding: 1em 0.55em;
    border-radius: 50px;
    line-height: 0;
    margin-right: 1em;
    display: block;
    width: 50px;
    margin: 1em auto;
}

#facebook h2,
#email h2,
#google h2 {
    > button::before {
        text-decoration: none !important;
    }
}

.btn-link {
    text-decoration: none !important;
    span {
        text-decoration: underline !important;
    }
}

.footer {
    // background: #efebe8;
    margin-top: 3em;
    padding: 1em;

    .fa {
        background: $color__oldwalls_secondary;
        color: #fff;

        border-radius: 50%;
        height: 40px;
        line-height: 40px;
        width: 40px;
        margin: 8px 4px;

        &:hover {
            background: lighten($color__oldwalls_secondary, 10%);
        }
    }
}


.form-group {
    label {
        display: block !important;
        margin-bottom: 0.5rem;
    }
    .form-check-label {
        display: initial !important;
    }
}
.form-control {
    border: 1px solid $color__oldwalls_primary;
    border-radius: 0;
}

// Facebook like iframe
iframe {
    width: 100%;
}

.alert-danger {
    a {
        color: #721c24 !important;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}


@media (max-width: 768px)
{
    h1 {
        font-size: 2.0rem;
    }
    img {
        width: 150px;
    }
    .section-wrapper {
        padding: 0.3em;
    }
    .card-body {
        padding: 0;
    }
}
